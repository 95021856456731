<template>
  <b-overlay :show="showOverlay">
    <b-col class="mb-1 bg-white p-0" cols="12">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <validation-observer ref="createGameServiceCategory" tag="div">
      <b-card class="data-edit-wrapper">
        <h2>Create Game Service Category</h2>

        <b-form class="mt-2">
          <b-row>
            <b-col class="" md="4">
              <b-form-group
                  label="Title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="data.title"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col class="" md="4">
              <b-form-group
                  label="Sub Title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="data.subTitle"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="gameServiceCategories" md="4">
              <b-form-group
                  label="Parent"

              >
                <!--                <validation-provider-->
                <!--                    #default="{ errors }"-->
                <!--                    rules="required"-->
                <!--                >-->
                <v-select
                    v-model="parentId"
                    :options="gameServiceCategories"
                    :reduce="title => title.id"
                    dir="ltr"
                    label="title"
                />
                <!--                </validation-provider>-->
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group
                  label="Short Description"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="data.shortDescription"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col class="my-1" cols="12">
              <b-form-group
                  label="Main Picture"
              >
                <MediaHandler ref="mediaHandler" @sendData="setFiles"></MediaHandler>

              </b-form-group>

            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Full Description"
              >
                <ckEditorMain ref="editorContent" :defaultContent="data.fullDescription"
                              @getEditorContent="setEditorContent"
                ></ckEditorMain>

              </b-form-group>

            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-card>
        <b-row>
          <b-col v-if="data" cols="12" md="12">
            <h2>SEO</h2>
          </b-col>
          <b-col class="" cols="12" md="4">
            <b-form-group
                label="Seo Title"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="data.seoTitle"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" cols="12" md="4">
            <b-form-group
                label="Seo Url"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="allCharactersToDash"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" cols="12">
            <b-form-group
                label="Seo Description"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >

                <b-form-textarea
                    id="data-edit-seoTitle"
                    v-model="data.seoDescription"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="pl-1">
            <div class="col-md-12 ">
              <!--- serp Desktop --->
              <div class="serp-preview d-lg-flex d-none flex-column align-items-start">
                <div class="serp-title">
                  <h4 style="color: rgb(26, 13, 171)!important">
                    {{ data.seoTitle }}
                  </h4>
                </div>
                <div class="serp-url">
                  <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                    <span>https://skycoach.com/[game-name]/<span class="serp-arrow"></span></span>
                    <span style="overflow-wrap: anywhere">
                      {{ data.seoUrl.replaceAll(' ', '-') }}
                    </span>

                  </h5>
                </div>
                <div class="serp-description">
                  <h6 style="overflow-wrap: anywhere">
                    {{ data.seoDescription }}
                  </h6>
                </div>
              </div>
              <!--- serp Mobile --->
              <div class="serp-preview d-lg-none  p-1 d-flex flex-column align-items-start">
                <div class="serp-title">
                  <h6>
                    {{ data.seoTitle }}
                  </h6>
                </div>
                <div class="serp-url d-flex align-items-center flex-wrap">

                  <small>{{ data.seoUrl.replaceAll(' ', '-') }}/</small>
                  <small>
                    https://skycoach.com/[game-name]/
                    <small class="serp-arrow"></small>
                  </small>
                </div>
                <div class="serp-description">
                  <small>
                    {{ data.seoDescription }}
                  </small>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <b-button
                class="btn-tour-skip mr-1"
                variant="primary"
                @click="createData"
            >
              <span class="mr-25 align-middle">Submit</span>

            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>

  </b-overlay>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BOverlay, BRow, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { CreateGameServiceCategory } from '@/libs/Api/gameServiceCategory'
import { GetGameServiceCategoriesByGameId } from '@/libs/Api/games'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'

export default {
  components: {
    DynamicBreadCrumb,
    MediaHandler,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ckEditorMain,
    AppCollapse,
    AppCollapseItem,
    BCard,
    ValidationObserver,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,
    vSelect,
  },
  name: 'create-game-service-category',
  title: 'create game service category',
  data() {
    return {
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      gameServiceCategories: null,
      parentId: '',
      pageBreadcrumb: [
        {
          text: 'Manage Games',
          href: '/apps/games/manage-games',
          active: false
        },
        {
          text: `Manage Game`,
          href: `/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active: false
        },
        {
          text: `Create Game Service Category`,
          href: `/apps/games/game-service-categories/create-game-service-category?gameId=${this.$route.query.gameId}`,
          active: true
        },
      ],

      data: {
        title: '',
        subTitle: '',
        fullDescription: '',
        shortDescription: '',
        imageFile: null,
        seoUrl: '',
        seoTitle: '',
        seoDescription: '',
      },
    }
  },
  computed: {
    allCharactersToDash: {
      get: function () {
        return this.data.seoUrl
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.data.seoUrl = newValue
          // Remove all characters that are NOT number
          this.data.seoUrl = this.data.seoUrl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, '-')
          this.data.seoUrl = this.data.seoUrl.replaceAll(' ', '-')
        } else if (!newValue || this.data.seoUrl === '') {
          this.data.seoUrl = null
        }
      },
    },
  },
  async created() {
    await this.getGameServiceCategories()
  },
  methods: {
    async getGameServiceCategories() {
      let _this = this
      _this.showOverlay = true

      let getGameServiceCategoriesByGameId = new GetGameServiceCategoriesByGameId(_this)
      let data = {
        PageNumber: 1,
        count: 200
      }
      getGameServiceCategoriesByGameId.setPartialUrl({ id: _this.$route.query.gameId })
      getGameServiceCategoriesByGameId.setParams(data)
      await getGameServiceCategoriesByGameId.fetch(function (content) {
        _this.showOverlay = false
        _this.gameServiceCategories = content.data.data

      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async setFiles(data) {
      if (data) {
        this.data.imageFile = data
      }
    },
    setEditorContent(content) {
      this.data.fullDescription = content
    },
    async createData() {
      let _this = this
      Helper.validateForm(_this, 'createGameServiceCategory', async () => {
        _this.showOverlay = true
        let createGameServiceCategory = new CreateGameServiceCategory(_this)
        createGameServiceCategory.setQueries({
          gameId: _this.$route.query.gameId,
          parentId: _this.parentId
        })
        this.$refs.mediaHandler.sendFiles()
        let myForm = await Helper.makeFormData(this.data)
        createGameServiceCategory.setFormData(myForm)
        await createGameServiceCategory.sendFormData(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.$router.push(`/apps/games/manage-game?gameId=${_this.$route.query.gameId}`)
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },
  }
}
</script>

<style scoped>

</style>
