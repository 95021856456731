import WebServiceRequest from '../Api/WebServiceRequest'

class CreateGameServiceCategory extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag(`Game/CreateGameServiceCategory`)

  }

  setQueries(data) {
    super.setRequestParam(data)

  }

  setParams(data) {
    super.setFormData(data)
  }
}

class DeleteGameServiceCategory extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag(`Game/DeleteGameServiceCategory`)

  }

  setParams(data) {
    super.setRequestParam(data)
  }
}
class UpdateGameServiceCategory extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag(`Game/UpdateGameServiceCategory`)


  }
  setQueryParams(data){
    super.setRequestParam(data)
  }
  setParams(data) {
    super.setFormData(data)
  }
}
class GetGameServiceCategoryDetail extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')

  }
  setPartail(data){
    super.setTag(`Game/GetGameServiceCategoryBy/${data.id}`)

  }
  setParams(data) {
    super.setRequestParam(data)
  }
}

export {
  CreateGameServiceCategory,
  DeleteGameServiceCategory,
  GetGameServiceCategoryDetail,
  UpdateGameServiceCategory
}
